body.contenttype-person {
  .presenter-view {
    margin-top: $spacing-medium;
    .presenter-labels {
      margin: $spacing-xs 0;
    }
    .presenter-name {
      @include add(size, 3xl);
      @include add(weight, bold);
      @include add(height, 3xl);
    }
    .presenter-description {
      @include add(size, m);
      @include add(height, m);
      color: $grey;
    }
    .presenter-preview-image img {
      width: 100%;
      height: 100%;
      border-radius: 10%;
      aspect-ratio: 1;
      -o-object-fit: cover;
      object-fit: cover;
    }
    .links {
      justify-content: left;
      .links.wrapper {
        .link.item {
          > svg {
            height: 24px !important;
            filter: grayscale(100%) contrast(1.1);
          }
          &:hover {
            > svg {
              filter: unset;
            }
          }
        }
      }
    }
    .presenter-activity {
      h2 {
        margin-top: $spacing-small;
        margin-bottom: $spacing-small;
        text-align: left;
      }
    }
  }
  @media only screen and (max-width: $tablet-breakpoint) {
    .presenter-view {
      padding-right: $spacing-medium;
      padding-left: $spacing-medium;
    }
  }
}
