#page-add .block.listing,
#page-edit .block.listing,
#page-document .block.listing,
#page-add .block.search,
#page-edit .block.search,
#page-document .block.search {
  h2.headline {
    margin-bottom: 50px;
    @include add(size, xl);
    @include add(weight, bold);
    @include add(height, 2xl);
    text-align: center;
    text-transform: uppercase;
  }
  .items {
    &.profiles {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  }
  .listing-item:last-child {
    padding-bottom: 0 !important;
    border-bottom: none !important;
  }
}
