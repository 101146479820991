body.no-roles {
  #toolbar {
    .toolbar-body {
      background-color: lightcyan;
    }
  }
}

#toolbar {
  .toolbar-body {
    .toolbar-bottom {
      #toolbar-personal {
        display: flex;
        overflow: hidden;
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        img.picture.small {
          width: auto;
          height: 50px;
        }
      }
    }
  }
}
