:root {
  --eventbrite-cta-background-color: #d1410c;
  --eventbrite-cta-border: #d1410c;
  --eventbrite-cta-text-color: #ffffff;
  --eventbrite-cta-padding: 10px 20px;
  --eventbrite-cta-font-size: 18px;
  --eventbrite-cta-font-weight: 500;
  --eventbrite-cta-hover-background-color: #9e2e05;
  --eventbrite-cta-hover-border: #9e2e05;
  --eventbrite-cta-hover-font-size: 18px;
  --eventbrite-cta-hover-font-weight: 500;
  --eventbrite-placeholder-header-font-size: 20px;
  --eventbrite-placeholder-header-font-weight: 700;
  --eventbrite-placeholder-text-font-size: var(--eventbrite-cta-font-size);
  --eventbrite-placeholder-text-font-weight: var(--eventbrite-cta-font-weight);
}

.block.checkoutBlock {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .placeholder {
      font-size: var(--eventbrite-placeholder-text-font-size);
      font-weight: var(--eventbrite-placeholder-text-font-weight);
      &.header {
        padding: var(--eventbrite-cta-padding);
        font-size: var(--eventbrite-placeholder-header-font-size);
        font-weight: var(--eventbrite-placeholder-header-font-weight);
      }
    }
    .eventbrite-cta {
      padding: var(--eventbrite-cta-padding);
      border: var(--eventbrite-cta-border);
      background-color: var(--eventbrite-cta-background-color);
      color: var(--eventbrite-cta-text-color);
      cursor: pointer;
      font-size: var(--eventbrite-cta-font-size);
      font-weight: var(--eventbrite-cta-font-weight);

      &.left {
        margin-right: auto;
        margin-left: 0px;
      }
      &.center {
        margin-right: auto;
        margin-left: auto;
      }
      &.right {
        margin-right: 0px;
        margin-left: auto;
      }
      &:hover {
        border: var(--eventbrite-cta-hover-border);
        background-color: var(--eventbrite-cta-hover-background-color);
        font-size: var(--eventbrite-cta-hover-font-size);
        font-weight: var(--eventbrite-cta-hover-font-weight);
      }
    }
  }
}
