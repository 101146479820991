.streamContainer {
  display: flex;
  .column {
    &.stream {
      min-width: 70%;
      height: 0;
      padding-bottom: 56.25%;
      .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &.streamChat {
      max-width: 30%;
      padding-left: $spacing-small;
      iframe {
        min-width: 100%;
        height: 100%;
        border: 0px;
      }
    }
  }
}

.slotLiveStream {
  button.liveStreamButton {
    padding: 2px;
    border-color: var(--stream-color) !important;
    border-radius: 20%;
    background-color: var(--stream-color) !important;
    color: var(--stream-color) !important;
    svg {
      height: 20px !important;
      fill: var(--reverse-color);
    }
    &:hover {
      border-color: var(--stream-color-alternate) !important;
      background-color: var(--stream-color-alternate) !important;
      color: var(--stream-color-alternate) !important;
    }
  }
}

.liveStreamModal.ui.modal {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  .streamContainer {
    min-height: 60vh;
    flex-direction: row;
    justify-content: center;
    .column {
      &.streamChat {
        min-width: 30%;
        padding-left: $spacing-small;
      }
    }
  }
}
