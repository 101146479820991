.header-wrapper .header {
  margin-right: 0px;
  margin-left: 0px;

  .logo-nav-wrapper {
    padding-bottom: $spacing-small;
  }
}

.navigation {
  .conf-item {
    &.tickets,
    &.ingressos {
      .item {
        @include ctaButton();
      }
    }
    .item {
      padding: $spacing-xs 0 $spacing-xs 0;
      margin-top: $spacing-xs;
      margin-left: $spacing-small;
      text-transform: uppercase !important;
      &.active,
      &:hover {
        color: var(--main-color);

        &::before {
          border-bottom: 9px solid var(--main-color);
        }
      }
    }
  }

  ul.desktop-menu .submenu-wrapper {
    margin-top: 30px;

    .submenu {
      background: var(--main-color);

      &.active {
        .close {
          background: var(--main-color);
          color: var(--reverse-color);
        }
      }

      .submenu-inner {
        h2 {
          color: var(--reverse-color);
        }

        .submenu-header {
          h2 {
            margin-left: 0px;
            font-weight: 700;
          }
        }

        li {
          &.subitem-wrapper {
            border-left: 1px solid var(--reverse-color);
          }
          > a {
            color: var(--reverse-color);

            span {
              &:hover {
                border-bottom: 1px solid var(--reverse-color);
              }
              color: var(--reverse-color);
            }
          }
        }
      }
    }
  }
}

.logo-nav-wrapper {
  img.image {
    &.logoConf {
      &.main {
        width: 178px;
        height: 100px;
        @media only screen and (max-width: $largest-mobile-screen) {
          margin-left: $spacing-small;
        }
      }

      &.switch {
        width: 89px;
        height: 50px;
      }
    }
  }
}

.header-wrapper .header .logo-nav-wrapper .site-selector {
  flex: 1 1 0;
  align-self: center;
  @media only screen and (max-width: $largest-mobile-screen) {
    display: none;
  }
}

.header-wrapper .header .logo-nav-wrapper .mobile-nav .site-selector {
  @media only screen and (max-width: $largest-mobile-screen) {
    display: block;
  }
}

.header-wrapper .header .logo-nav-wrapper .mobile-nav {
  @media only screen and (max-width: $largest-mobile-screen) {
    align-self: center;
    margin-right: 10px;

    .hamburger-wrapper .hamburger {
      &::before {
        background-color: var(--main-color);
      }

      &::after {
        background-color: var(--main-color);
      }

      .hamburger-box {
        .hamburger-inner {
          &::before {
            background-color: var(--main-color);
          }

          &::after {
            background-color: var(--main-color);
          }
        }
      }
    }
  }
}
