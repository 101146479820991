.drop-indicator {
  width: 100%;
  min-height: 6rem;
}

.drop-indicator .ui.message {
  /* padding: 0px; */
}

.drop-indicator .ui.message center {
  height: 100%;
  /* margin: 0 auto; */
}

.drop-indicator .ui.message center img {
  width: 100%;
  height: 100%;
}
