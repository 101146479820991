.blocks-group-wrapper {
  @include color-block-change-vertical-spacing();
}

.blocks-group-wrapper.grey {
  background-color: #ecebeb;
}

.has--backgroundColor--grey {
  background: $lightgrey;
}

#page-document .blocks-group-wrapper {
  &.transparent:first-child {
    padding-top: 0;
  }
  .block:first-child h2,
  .block:first-child h2.headline {
    margin-top: 0;
  }
}

// If next block has different bgcolor, cancel all margin-bottom
#page-document .block.next--has--different--backgroundColor {
  margin-bottom: 0 !important;
}

// For grids
#page-document .blocks-group-wrapper .block.gridBlock {
  &.is--first--of--block-type.previous--has--same--backgroundColor {
    @include grid-block-vertical-spacing-top();
  }
  &.is--last--of--block-type.next--has--same--backgroundColor {
    margin-bottom: $grid-block-vertical-spacing-bottom;
  }
  &.next--is--same--block-type.next--has--same--backgroundColor {
    // We rely on the grid gutter to keep the vertical spacing in case grid + grid
    // So here we cancel the default block margin-bottom
    margin-bottom: 0;
  }
}

// Buttons
#page-document
  .blocks-group-wrapper
  .block.__button.next--has--different--backgroundColor {
  margin-bottom: 0;

  .button.container {
    margin-bottom: 0;
  }
}

// Slider
#page-document .blocks-group-wrapper.grey:has(.block.slider:first-child) {
  padding-top: 0;
  padding-bottom: 0px; // Adjust for the padding from the slider itself
}

#page-document .blocks-group-wrapper.grey .block.slider,
.block-editor-slider.has--backgroundColor--grey {
  .slider-dot:not(.slider-dot--selected)::after {
    background: #fff;
  }
}

// Edit mode adjustments
.block-editor-gridBlock.has--backgroundColor--grey .block h2.headline {
  padding-top: $spacing-xlarge;
}
