.q.container,
.a.container {
  container-type: inline-size;

  &.layout {
    @include layout-container-width;
  }

  &.narrow {
    @include narrow-container-width;
  }
}
