@import './Button.css';
@import './Dialog.css';
@import './Switch.css';
@import './theme.css';

.react-aria-Popover {
  --background-color: var(--overlay-background);
  max-width: 250px;
  box-sizing: border-box;

  border: 1px solid var(--border-color);
  border-radius: 6px;
  background: var(--background-color);
  box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
  color: var(--text-color);
  outline: none;

  .react-aria-OverlayArrow svg {
    display: block;
    fill: var(--background-color);
    stroke: var(--border-color);
    stroke-width: 1px;
  }

  &[data-placement='top'] {
    --origin: translateY(8px);

    &:has(.react-aria-OverlayArrow) {
      margin-bottom: 6px;
    }
  }

  &[data-placement='bottom'] {
    --origin: translateY(-8px);

    &:has(.react-aria-OverlayArrow) {
      margin-top: 6px;
    }

    .react-aria-OverlayArrow svg {
      transform: rotate(180deg);
    }
  }

  &[data-placement='right'] {
    --origin: translateX(-8px);

    &:has(.react-aria-OverlayArrow) {
      margin-left: 6px;
    }

    .react-aria-OverlayArrow svg {
      transform: rotate(90deg);
    }
  }

  &[data-placement='left'] {
    --origin: translateX(8px);

    &:has(.react-aria-OverlayArrow) {
      margin-right: 6px;
    }

    .react-aria-OverlayArrow svg {
      transform: rotate(-90deg);
    }
  }

  &[data-entering] {
    animation: popover-slide 200ms;
  }

  &[data-exiting] {
    animation: popover-slide 200ms reverse ease-in;
  }
}

@keyframes popover-slide {
  from {
    opacity: 0;
    transform: var(--origin);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
