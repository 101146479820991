body.section-sponsors,
body.section-patrocinio {
  &.contenttype-sponsorlevel {
    .accordion-block {
      ul {
        > li {
          &::marker {
            content: '✅ ';
          }
        }
      }
    }
  }
}
