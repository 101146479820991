.section-en,
.section-pt-br {
  h1.documentFirstHeading {
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
