body.contenttype-sponsor-level,
.accordion-block .ui.styled.accordion {
  .title.accordion-title {
    > span {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 30px;
      font-weight: 700;
      -webkit-hyphens: auto;
      hyphens: auto;
      line-height: 36px;
      overflow-wrap: break-word;
      word-break: break-word;
      word-wrap: break-word;
    }
  }
}
