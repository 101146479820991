body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li {
  font-family: $page-font;
  font-style: normal;
  @include body-text();
}

@mixin word-break {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}

li::marker {
  line-height: 32px;
}

.content-area,
.breadcrumbs {
  p > a,
  li > a,
  .breadcrumb a {
    color: $blue-for-grey-contrast !important;
    text-decoration: underline;
  }
}

p,
ul,
ol {
  margin-bottom: $block-vertical-space;
  @include word-break();

  &:last-child {
    margin-bottom: 0;
  }
}

strong {
  @include add(weight, bold);
}

em,
em span span {
  font-style: italic;
}

#page-document {
  blockquote {
    padding: 18px 40px;
  }
}

h1.documentFirstHeading {
  border: none;
  @include page-title();
  @include vertical-space-h1();
  @include word-break();
  span {
    border: none;
    @include page-title();
  }

  &::before {
    display: none;
  }
}

// Block Title
.block h2.headline {
  @include block-title();
  @include vertical-space-block-title();
}

// Heading block
.heading-wrapper h2,
.block .heading-wrapper h2:last-child {
  // Needed to cancel the Semantic one
  @include block-title();
  @include vertical-space-heading();
}

.heading-wrapper h3 {
  padding-top: 3rem;
  padding-bottom: 1rem;
  font-size: 42px;
  font-weight: $light;
  line-height: 48px;
}

// Sept2023 - @sneridagh: removing this, since it's breaking
// the layout in edit view. Can't say why it was here in the
// first place, we'll see if it breaks in another place after it.
// .block-editor-heading {
//   .block .heading-wrapper {
//     margin-right: 0 !important;
//     margin-left: 0 !important;
//   }
// }

// Slate headings
h2 {
  @include text-heading-h2();
  @include vertical-space-h2();
  @include word-break();
  span {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}
h3 {
  @include text-heading-h3();
  @include vertical-space-h3();
  @include word-break();
  span {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}
h4 {
  @include text-heading-h4();
  @include vertical-space-h4();
  @include word-break();
  span {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

// Head-title
.head-title,
.head-title span {
  @include body-text-bold();
  @include word-break();
  padding-bottom: 10px;
  letter-spacing: 1px;
  text-transform: uppercase !important;
}

#page-add,
#page-edit {
  .head-title,
  .head-title span {
    user-select: none;
  }
}
