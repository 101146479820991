.block.follow_us {
  &.full,
  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  .follow_us.title {
    font-size: 1.7rem;
    font-weight: 500;
  }

  .social-networks {
    padding-top: 0.5rem;
  }
}

// Block inside a grid
.block.gridBlock {
  .block.follow_us.center {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
}
